<template>
  <v-card
    outlined
    :style="isClosed ? 'border: 1px red solid' : ''"
  >
    <v-card-title class="py-0">
      <v-row
        align="center"
        justify="space-between"
      >
        <v-col cols="auto">
          {{ weekday.name }}
        </v-col>
        <v-col
          v-if="!emptyIsDefaultOpen"
          cols="auto"
        >
          <v-checkbox
            :label="$t('closed')"
            v-model="isClosed"
            hide-details
            class="ma-0"
            :color="isClosed ? 'red' : ''"
          />
        </v-col>
        <v-col
          cols="auto"
          v-if="showSlotSwitch"
        >
          <v-switch
            v-if="userIsAdmin"
            :label="$t('slots')"
            v-model="showCustomSlotOptions"
            hide-details
            class="ma-0"
            disabled
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text
      class="pb-0 pt-2"
      v-if="pricesByWeekdays.length < 1"
    >
      <div
        v-if="isClosed && openingRule === 'location_is_always_open' && value.length > 0"
        class="red--text"
      >
        {{ $t('onlineOrderingIsClosedOn') }} {{ $t(weekday.name + 'sLC') }}
      </div>
      <div
        v-if="isClosed && openingRule !== 'location_is_always_open'"
        class="red--text"
      >
        {{ $t('closedOn') }} {{ $t(weekday.name + 'sLC') }}
      </div>
      <div
        v-if="openingRule === 'location_is_always_open' && value.length < 1"
        class="green--text"
      >
        {{ $t('locationIsAlwaysOpen') }}!
      </div>
      <div
        v-if="emptyIsDefaultOpen"
        class="green--text"
      >
        {{ $t('categoryIsOpenOnThisDayByDefault') }}
      </div>
      <div>{{ $t('noWeekdayPricesSet') }}</div>
    </v-card-text>
    <v-card-text
      class="py-0"
      v-else
    >
      <template v-for="(weekdayPrice, index) in pricesByWeekdays">
        {{ $t('weekdayPrice') }}
        <v-row
          :key="'d-' + weekday.day + '-' + index"
          v-if="index < pricesByWeekdays.length - 1"
          dense
          align="center"
          justify="center"
        >
          <v-col
            cols="auto"
            class="grow"
          >
            <v-divider />
          </v-col>
          <v-col cols="auto">
            <p
              v-if="index < pricesByWeekdays.length - 1"
              class="mx-auto mt-1 mb-0 text-subtitle-1"
            >
              {{ $t('AND') }}
            </p>
          </v-col>
          <v-col
            cols="auto"
            class="grow"
          >
            <v-divider />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-container fluid>
        <v-row
          justify="space-between"
          dense
        >
          <v-col
            cols="auto"
            class="grow"
          >
            <v-btn
              block
              large
              text
              color="primary"
              @click="addNewWeekdayPrice(weekday.day)"
              class="text-body-2 text-md-subtitle-1"
            >
              <v-icon left>
                mdi-plus
              </v-icon>{{ $t('addAnotherWeekdayPrice') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
	name: "Weekday",
	components: {
	},
	props: {
		weekday: {
      type: Object,
      default: () => {}
    },
		value: {
      type: Array,
      default: () => []
    },
	},
	data() {
		return {
		}
	},
	computed: {
    pricesByWeekdays() {
      if(!this.weekday || !this.weekday.weekdayPrices || !Array.isArray(!this.weekday.weekdayPrices) || this.weekday.weekdayPrices.length < 1) {
        return []
      }
      return this.weekday.weekdayPrices.filter(weekday => weekday.day === this.weekday.day)
    }
	},
	methods: {
		addNewWeekdayPrice(weekday) {
			this.$emit('addNewWeekdayPrice', weekday.day)

		},
	}
}
</script>

<style scoped>

</style>